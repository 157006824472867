import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await fetch("https://www.panafricanbookexchange-wandererspath.com/login-user", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      console.log(data, "userLogin");

      if (data.status === "ok") {
        alert("Login successful");

        // Store user info in localStorage
        window.localStorage.setItem("token", data.token); // Store JWT
        window.localStorage.setItem("userType", data.userType); // Store user type
        window.localStorage.setItem("loggedIn", "true"); // Mark as logged in

        // Navigate to AddBookForm or other page based on userType
        if (data.userType === "admin") {
          navigate("/add-book");
        } else {
          navigate("/add-book"); // Redirect to a user-specific page
        }
      } else {
        alert(data.error || "Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred. Please try again.");
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleSubmit}>
          <h3>Authors' Login</h3>
          <div className="mb-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-left">
            Don't have an account? <a href="/registerAdmin">Register</a>
          </p>
	

        </form>
      </div>
    </div>
  );
}
