import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Add Link for navigation
import AddBookForm from './components/AddBookForm';
import BookList from './components/BookList';
import BookGraph from './components/BookGraph';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './components/HomePage';
import ArtList from './components/ArtList';
import AddArtForm from './components/AddArtForm';
import Cart from './components/Cart';
import PaymentForm from './components/PaymentForm';
import DownloadPage from './components/DownloadPage';
import Founder from './components/Founder'; // Import Founder component
import Login1 from "./components/login_component"; // Corrected component name here
import SignUp from "./components/signup_component";
import PaymentManagement from './components/PaymentManagement'; // Import the new PaymentManagement component
import ForgotPassword from './components/ForgotPassword'; // Import the ForgotPassword component

import './App.css';
import logo from './images/logo.png';

function App() { 

  return (
    <Router>
      <div className="App">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

        <h1>PAN AFRICAN BOOK EXCHANGE</h1>
        <nav>
          <h2>EXPLORE YOUR EBOOK COLLECTION</h2>
        </nav>
      
        {/* Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ebooks" element={<BookList />} />
          <Route path="/arts" element={<ArtList />} />
          <Route path="/add-book" element={<AddBookForm />} />
          <Route path="/add-arts" element={<AddArtForm />} />
          <Route path="/graph/:id" element={<BookGraph />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin" element={<Login1 />} /> {/* Corrected component name here */}
          <Route path="/registerAdmin" element={<SignUp />} /> {/* Corrected component name here */}
          <Route path="/payment-form" element={<PaymentForm />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/founder" element={<Founder />} /> {/* New Founder route */}
          <Route path="/payment-management" element={<PaymentManagement />} /> {/* New PaymentManagement route */}
          <Route path="/forgotpassword" element={<ForgotPassword />} /> {/* Added ForgotPassword route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
