import React, { useState } from "react";
import "./signup.css";

export default function SignUp() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [kraPinFile, setKraPinFile] = useState(null);
  const [copyrightCertificate, setCopyrightCertificate] = useState(null);
  const [originalWorkConfirmation, setOriginalWorkConfirmation] = useState(false);

  // Handler for KRA PIN file upload (disguised; not part of submission)
  const handleKraPinChange = (e) => {
    setKraPinFile(e.target.files[0]);
    console.log("KRA PIN File selected:", e.target.files[0]);
  };

  // Handler for Copyright Certificate file upload (disguised; not part of submission)
  const handleCopyrightCertificateChange = (e) => {
    setCopyrightCertificate(e.target.files[0]);
    console.log("Copyright Certificate selected:", e.target.files[0]);
  };

  // Handler for original work confirmation checkbox
  const handleOriginalWorkConfirmationChange = (e) => {
    setOriginalWorkConfirmation(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!fname || !lname || !email || !password || !userType || !originalWorkConfirmation) {
      alert("Please fill in all required fields and confirm your original work");
      return;
    }

    try {
      const response = await fetch("https://www.panafricanbookexchange-wandererspath.com/registeradmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fname,
          lname,
          email,
          password,
          userType,
        }),
      });

      const data = await response.json();
      if (data.status === "ok") {
        alert("Registration Successful");

        // Store JWT token and user data
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("userType", data.userType);
        window.localStorage.setItem("loggedIn", "true");

        // Redirect to appropriate page
        if (data.userType === "admin") {
          window.location.href = "/add-book";
        } else {
          window.location.href = "/admin";
        }
      } else {
        console.error("Server Error:", data.error || "Unknown error");
        alert(data.error || "Something went wrong");
      }
    } catch (err) {
      console.error("Network Error:", err);
      alert("A network error occurred. Please try again.");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleSubmit}>
          <h3>REGISTER AUTHORS</h3>
          <div>
            <input
              type="radio"
              name="UserType"
              value="Admin"
              onChange={(e) => setUserType(e.target.value)}
            />
            I am an Author
          </div>

          <div className="mb-3">
            <label>First name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              onChange={(e) => setFname(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label>Last name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              onChange={(e) => setLname(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <label className="label">Upload KRA PIN or Tax Certificate</label>
          <input
            type="file"
            onChange={handleKraPinChange}
            accept="image/*,.pdf"
          />

          <label className="label">Upload National Identification (ID)</label>
          <input
            type="file"
            onChange={handleCopyrightCertificateChange}
            accept="image/*,.pdf"
          />

          <label>
            <input
              type="checkbox"
              name="originalWorkConfirmation"
              onChange={handleOriginalWorkConfirmationChange}
            />
            I hereby confirm that all details provided above are accurate and truthful to the best of my knowledge.
          </label>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Register
            </button>
          </div>
          <p className="forgot-password text-right">
            Already registered <a href="/admin">Login?</a>
          </p>
        </form>
      </div>
    </div>
  );
}
