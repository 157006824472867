import React, { useState, useEffect } from 'react';
import './DownloadPage.css';

const DownloadPage = () => {
  const [email, setEmail] = useState('');
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const storedEmails = JSON.parse(localStorage.getItem('verifiedEmails')) || [];
    setVerifiedEmails(storedEmails);

    const links = JSON.parse(sessionStorage.getItem('downloadLinks'));
    const expirationTime = sessionStorage.getItem('downloadLinksExpiration');

    if (links && expirationTime) {
      const currentTime = new Date().getTime();
      const expTime = parseInt(expirationTime, 10);

      if (currentTime > expTime) {
        sessionStorage.removeItem('downloadLinks');
        sessionStorage.removeItem('downloadLinksExpiration');
        window.location.href = '/ebooks';
      } else {
        setDownloadLinks(links);
      }
    } else {
      window.location.href = '/ebooks';
    }
  }, []);

  const handleSendEmail = async () => {
  if (!email) {
    alert('Please enter an email address.');
    return;
  }

  const linksToSend = downloadLinks.map(item => item.link.url || item.link);

  try {
    const response = await fetch('/api/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, links: linksToSend }),
    });

    if (response.ok) {
      setEmailSent(true);
      alert(`Download link sent to ${email}.`); // Corrected line
      setEmail('');
      window.location.href = '/ebooks';
    } else {
      alert('Failed to send email. Please try again.');
    }
  } catch (error) {
    console.error('Error sending email:', error);
    alert('Error sending email. Please try again.');
  }
};

  const handleDownloadClick = async (link) => {
    if (verifiedEmails.includes(email)) {
      alert('This book is restricted to a verified email.');
      return;
    }

    try {
      const response = await fetch('/api/generate-restricted-book', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, link }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'RestrictedBook.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        alert('Failed to download the restricted book. Please try again.');
      }
    } catch (error) {
      console.error('Error downloading the restricted book:', error);
      alert('Error downloading the book. Please try again.');
    }
  };

  return (
    <div className="download-page">
      <h2 className="title">Thank You for Your Purchase!</h2>

      {!emailSent ? (
        <div className="email-section">
          <p>Enter your email address to receive the download link:</p>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <button onClick={handleSendEmail} className="send-btn">Send Link</button>
        </div>
      ) : (
        <p className="success-message">Successfully sent. Thank you!</p>
      )}

      {downloadLinks.length === 0 ? (
        <div className="no-links-section">
          <p>No download links available.</p>
          <button onClick={() => window.location.href = '/ebooks'} className="back-btn">
            Go to Books
          </button>
        </div>
      ) : (
        <ul className="links-list">
          {downloadLinks.map((item, index) => (
            <li key={index} className="link-item">
                          </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DownloadPage;