import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PaymentManagement.css';

const PaymentManagement = ({ userId }) => {
  const [sales, setSales] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [balance, setBalance] = useState(0);
  const [notification, setNotification] = useState('');
  const [progress, setProgress] = useState(0); // Progress for withdrawal process

  const transactionFeeRate = 0.05;
  const withdrawalFee = 100;
  const equityAccountNumber = '1450284160395';

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get(
          `https://www.panafricanbookexchange-wandererspath.com/api/sales/${userId}`
        );
        if (response.data.totalSales > sales) {
          setSales(response.data.totalSales);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSales();
  }, [userId]);

  useEffect(() => {
    const fetchAmountFromServer = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const amount = urlParams.get('amount') || 0;
        setBalance(parseFloat(amount));
      } catch (error) {
        console.error('Error fetching amount from server:', error);
      }
    };

    fetchAmountFromServer();
  }, []);

  const transactionFee = sales * transactionFeeRate;
  const availableBalance = balance || (sales - transactionFee);

  const handleWithdraw = async (e) => {
    e.preventDefault();
    const withdrawalAmount = parseFloat(withdrawAmount);

    if (withdrawalAmount <= 0 || withdrawalAmount > availableBalance) {
      setNotification('Invalid withdrawal amount.');
      return;
    }

    const finalAmount = withdrawalAmount - withdrawalFee;

    if (finalAmount <= 0) {
      setNotification('Withdrawal amount after fee is too low.');
      return;
    }

    setNotification('Processing withdrawal...');
    setProgress(20);

    try {
      const payload = {
        amount: finalAmount,
        accountNumber: equityAccountNumber,
      };

      const response = await axios.post('http://localhost/pesapal/withdraw.php', payload);

      setProgress(100);
      if (response.data.success) {
        setNotification(`Withdrawal successful. Amount transferred: KSH ${finalAmount}`);
        setBalance(balance - withdrawalAmount);
      } else {
        setNotification(`Withdrawal failed: ${response.data.message || 'Please try again.'}`);
      }
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      setNotification('Error processing withdrawal. Please try again.');
      setProgress(0);
    }
  };

  return (
    <div className="payment-management">
      <h2>Payment Management</h2>
      <p>Total Sales: KSH {sales}</p>
      <p>Transaction Fee (5%): KSH {transactionFee}</p>
      <p>Available Balance: KSH {availableBalance}</p>

      <form onSubmit={handleWithdraw}>
        <input
          type="number"
          placeholder="Enter amount to withdraw"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          required
        />
        <button type="submit">Withdraw</button>
      </form>

      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>

      {notification && <div className="notification">{notification}</div>}
    </div>
  );
};

export default PaymentManagement;
