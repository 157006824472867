import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddBookForm.css';
import { Link, useLocation } from 'react-router-dom';
import "../App.css";



const DisclaimerPopup = ({ onLogout }) => {
  const location = useLocation();
  const username = location.state?.username || "Guest";

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "./admin";
  };

  return (
    <div>
      <br />
      <div className="auth-inner">
        <div>
          <h1>Welcome, {username}!</h1>
          <button onClick={logOut} className="btn btn-primary">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

const AddBookForm = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [price, setPrice] = useState(''); // USD price input
  const [category, setCategory] = useState('');
  const [ebook, setEbook] = useState(null);
  const [image, setImage] = useState(null);
  const [biographyImage, setBiographyImage] = useState(null);
  const [discountRange, setDiscountRange] = useState('');
  const [notification, setNotification] = useState('');
  const [books, setBooks] = useState([]);
  const [editingBookId, setEditingBookId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [userData, setUserData] = useState(null); // User authentication state


  const [, setCopyrightConfirmation] = useState('');
  const [, setOriginalWorkConfirmation] = useState('');
  //const [kraPin, setKraPin] = useState(null); // State for KRA PIN file
  //const [copyrightCertificate, setCopyrightCertificate] = useState(null); // State for Copyright Certificate file
 


  const booksPerPage = 8;

  const discountOptions = [
    { label: '5-10%', value: 0.10 },
    { label: '10-15%', value: 0.15 },
    { label: '15-20%', value: 0.20 },
    { label: '20-30%', value: 0.30 },
  ];
  



 // Fetch user data and authenticate token
//   useEffect(() => {
//     async function fetchUserData() {
//       try {
//         const response = await fetch("http://195.35.48.252:5000/userData", {
//           method: "POST",
//           crossDomain: true,
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             "Access-Control-Allow-Origin": "*",
//           },
//           body: JSON.stringify({
//             token: window.localStorage.getItem("token"),
//           }),
//         });

//         const data = await response.json();
//         if (data.data === "token expired") {
//           alert("Token expired, please login again.");
//           window.localStorage.clear();
//           window.location.href = "./admin";
//         } else {
//           setUserData(data.data);
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         alert("An error occurred. Please try again.");
//         window.location.href = "./admin";
//       }
//     }

//     fetchUserData();
//   }, []);


  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get('https://www.panafricanbookexchange-wandererspath.com/api/books');
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };
    fetchBooks();
  }, []);

  const convertUsdToFanon = (usdPrice) => usdPrice * 100;
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const minPrice = price - (price * (discountRange || 0));
    if (price < minPrice) {
      setNotification(`Price cannot go below the selected discount threshold.`);
      return;
    }
  
    const priceInFanon = convertUsdToFanon(price);
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('price', priceInFanon); // Store price in Fanon
    formData.append('category', category);
    formData.append('discountRange', discountRange);
    if (ebook) formData.append('ebook', ebook); // 'ebook' must match the backend field name
    if (image) formData.append('image', image); // 'image' must match the backend field name
    if (biographyImage) formData.append('biographyImage', biographyImage); // 'biographyImage' must match the backend field name
  
    //if (kraPin) formData.append('kraPin', kraPin); // 'kraPin' should match backend field name
    //if (copyrightCertificate) formData.append('copyrightCertificate', copyrightCertificate); // 'copyrightCertificate' should match backend field name
  
    try {
      let response;
      if (editingBookId) {
        response = await axios.put(`https://www.panafricanbookexchange-wandererspath.com/api/books/${editingBookId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setBooks((prevBooks) =>
          prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
        );
      } else {
        response = await axios.post('https://www.panafricanbookexchange-wandererspath.com/api/books', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setBooks((prevBooks) => [...prevBooks, response.data]);
      }
      setNotification('Book added/updated successfully!');
      setTitle('');
      setAuthor('');
      setPrice('');
      setCategory('');
      setDiscountRange('');
      setEbook(null);
      setImage(null);
      setBiographyImage(null);
  
      //setKraPin(null);
      //setCopyrightCertificate(null);
  
      setEditingBookId(null);
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error during book submission:', error); // Log error to console for better debugging
      const errorMessage = error.response?.data?.message || 'Error adding/updating book. Please try again.';
      setNotification(errorMessage);
    }
  };
  
const handleFileChange = (e) => setEbook(e.target.files[0]);


const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);
  //const handleKraPinChange = (e) => {
   // const file = e.target.files[0];
    //setKraPin(file); // Assuming you have a state variable setKraPin
  //};
  
   // const handleCopyrightCertificateChange = (e) => {
    //const file = e.target.files[0];
   // setCopyrightCertificate(file); // Assuming you have a state variable setCopyrightCertificate
  //};
  

  const handleDelete = async (bookId) => {
    try {
      await axios.delete(`https://www.panafricanbookexchange-wandererspath.com/api/books/${bookId}`);
      setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
      setNotification('Book deleted successfully!');
    } catch (error) {
      setNotification('Error deleting book. Please try again.');
    }
  };

  const handleEdit = (book) => {
    setTitle(book.title);
    setAuthor(book.author);
    const priceInUsd = book.price / 100;
    setPrice(priceInUsd); // USD price
    setCategory(book.category);
    setDiscountRange(book.discountRange);
    setEditingBookId(book._id);
    setIsFormVisible(true);
  };

  //const handleShareLink = (book) => {
    //const shareableLink = `https://www.panafricanbookexchange-wandererspath.com/api/books/download/${book._id}`;
    //alert(`Share this link to download the eBook: ${shareableLink}`);
  //};



 const [buttonTexts, setButtonTexts] = useState(
    books.reduce((acc, book) => {
      acc[book._id] = 'Share Link'; // Initialize button text to 'Share Link' for each book
      return acc;
    }, {})
  );

  const handleShareLink = (book) => {
    //const shareableLink = `https://www.panafricanbookexchange-wandererspath.com/api/books/download/${book._id}`;
const shareableLink = `https://www.panafricanbookexchange-wandererspath.com/?redirect=/ebooks&bookId=${book._id}`;

    // Copy the shareable link to the clipboard
    navigator.clipboard.writeText(shareableLink).then(() => {
      // Update the button text for the clicked book to "Link Copied"
      setButtonTexts((prevState) => ({
        ...prevState,
        [book._id]: 'Link Copied'
      }));

      // Optionally, reset button text after 3 seconds
      setTimeout(() => {
        setButtonTexts((prevState) => ({
          ...prevState,
          [book._id]: 'Share Link'
        }));
      }, 3000); // Reset after 3 seconds
    }).catch((error) => {
      console.error('Failed to copy the link:', error);
    });
  };











  const filteredBooks = books.filter((book) =>
    book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    book.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
  const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

  return (
    <div className="add-book-form-container">
      {userData && showDisclaimer && (
        <DisclaimerPopup userData={userData} onLogout={() => setUserData(null)} />
      )}
      {notification && <div className="notification">{notification}</div>}
      <input
        type="search"
        placeholder="Search by title or author..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
          <br />
      <Link to="/payment-management">
        <button className="payment-management-button">
          Manage Withdrawals
        </button>
      </Link>
<br />
      <br />

	<Link to="/admin">
        <button className="logout-button">
          Logout
        </button>
      </Link>


      <br />
      <br />
      <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
        {isFormVisible ? 'Cancel' : 'Add Book'}
      </button>
      <br />

      {isFormVisible && (

        <form onSubmit={handleSubmit}> 
  <input
    type="text"
    placeholder="Title"
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    required
  />
  <input
    type="text"
    placeholder="Author"
    value={author}
    onChange={(e) => setAuthor(e.target.value)}
    required
  />
  <input
    type="number"
    placeholder="Price (USD)"
    value={price}
    onChange={(e) => setPrice(e.target.value)}
    required
  />
  <select value={category} onChange={(e) => setCategory(e.target.value)} required>
    <option value="">Select Category</option>
    <option value="Fiction">Fiction</option>
    <option value="Non-Fiction">Non-Fiction</option>
  </select>
  <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
    <option value="">Select Discount Range</option>
    {discountOptions.map((option) => (
      <option key={option.value} value={option.value}>{option.label}</option>
    ))}
  </select>
  <label className="label">Upload eBook PDF</label>
  <input type="file"  name="ebook" onChange={handleFileChange} accept=".pdf" required />
  <label className="label">Upload eBook Cover</label>
  <input type="file" onChange={handleImageChange} accept="image/*" required />
  <label className="label">Upload Author Biography Image</label>
  <input type="file" onChange={handleBiographyImageChange} accept="image/*" required />

  <fieldset>
    <legend>Copyright and Originality Confirmation</legend>
    <label>
      <input
        type="checkbox"
        name="copyrightConfirmation"
        value="yes"
        onChange={(e) => setCopyrightConfirmation(e.target.value)}
        required
      />
      Do you confirm that you own the copyright to the book being uploaded?
    </label>
    {/* <label>
      <input
        type="radio"
        name="originalWorkConfirmation"
        value="yes"
        onChange={(e) => setOriginalWorkConfirmation(e.target.value)}
        required
      />
      Is this book your original work, created solely by you?
    </label> */}
  </fieldset>

  <button type="submit" className="submit-button">Submit</button>
</form>

      )}

      <div className="book-list">
        {paginatedBooks.map((book) => (
          <div key={book._id} className="book-item">
            {book.imagePath && (
              <img src={`https://www.panafricanbookexchange-wandererspath.com/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
            )}
            <h3>{book.title}</h3>
            <p>Author: {book.author}</p>
            <p>Price: {(book.price / 100).toFixed(2)} USD / {book.price} Fanon</p>
            <p>Category: {book.category}</p>
            {/* <p>Discount Range: {discountOptions.find(option => option.value == book.discountRange)?.label || 'N/A'}</p> */}
            <button onClick={() => handleEdit(book)} className="edit-button">Edit</button>
            <button onClick={() => handleDelete(book._id)} className="delete-button">Delete</button>            
	    <button onClick={() => handleShareLink(book)} className="share-link-button">
            {buttonTexts[book._id]} SharedLink
          </button>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={page === currentPage ? 'active' : ''}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AddBookForm;
